import { HydrateOption } from '@core/enums';
import Flicking, { ALIGN, DIRECTION, } from '@egjs/react-flicking';
import { Grid } from '@helpers/grid';
import withWidget from '@hoc/withWidget';
import { LinkButton } from '@stories/Components/Buttons/Button/Button';
import NextButton from '@stories/Components/Buttons/NextButton/NextButton';
import PrevButton from '@stories/Components/Buttons/PrevButton/PrevButton';
import DocumentCard from '@stories/Components/Cards/DocumentCard/DocumentCard';
import Heading from '@stories/Components/Global/Typography/Heading';
import React, { useRef, useState } from 'react';
import S from './LatestNewsCarousel.styles';
const LatestNewsCarousel = ({ items = [], title, intro, cta, isLatestNewsWidget, }) => {
    const refFlicking = useRef(null);
    const itemListRef = useRef(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [showControls, setShowControls] = useState(false);
    const [controlsLength, setControlsLength] = useState(0);
    const handleSetControlsLength = (visiblePanelsLength) => {
        if (!itemListRef.current) {
            return;
        }
        const itemListStyles = getComputedStyle(itemListRef.current);
        const featureSize = parseInt(itemListStyles.getPropertyValue('--featureSize'), 10);
        const itemSize = parseInt(itemListStyles.getPropertyValue('--itemSize'), 10);
        let total = 0;
        let anyFeatured = false;
        for (let index = 0; index < items.length; index++) {
            const item = items[index];
            if (item.featured === true) {
                anyFeatured = true;
                total += featureSize;
            }
            else {
                total += itemSize;
            }
        }
        getComputedStyle(itemListRef.current);
        if (itemSize === 1 && featureSize === 1) {
            setControlsLength(total);
            return;
        }
        if (anyFeatured === false) {
            setControlsLength(total + 1 - visiblePanelsLength);
            return;
        }
        setControlsLength(total - 1 - visiblePanelsLength);
    };
    return (React.createElement(S.Container, null,
        React.createElement(Grid.Default, null,
            React.createElement(S.Inner, null,
                React.createElement(Heading, { visual: "h5", semantic: "h2" }, title),
                intro && React.createElement(S.Intro, null, intro),
                React.createElement(S.ItemList, { ref: itemListRef },
                    React.createElement(Flicking, { ref: refFlicking, align: ALIGN.PREV, bound: true, onAfterResize: (event) => {
                            // Move to first item after resizing to fix misalignment.
                            event.currentTarget.moveTo(0);
                            const visiblePanels = getVisiblePanels(event.currentTarget);
                            handleSetControlsLength(visiblePanels.length);
                            // Hide controls when all panels are visible.
                            setShowControls(!(visiblePanels.length === items.length));
                        }, onChanged: (event) => {
                            // Auto-focus the new slide if the focus was already inside the carousel.
                            if (event.currentTarget.element.contains(document.activeElement)) {
                                event.panel.element.focus();
                            }
                        }, onWillChange: (event) => {
                            if (event.index > items.length - 1) {
                                setActiveIndex(event.index - items.length);
                            }
                            else {
                                setActiveIndex(event.index);
                            }
                        }, onMoveStart: (event) => {
                            const visiblePanels = getVisiblePanels(event.currentTarget);
                            // Avoid swiping when all panels are visible.
                            if (visiblePanels.length === items.length) {
                                event.stop();
                            }
                        }, onReady: (event) => {
                            const visiblePanels = getVisiblePanels(event.currentTarget);
                            handleSetControlsLength(visiblePanels.length);
                            // Hide controls when all panels are visible.
                            setShowControls(!(visiblePanels.length === items.length));
                        } }, items.map((item, i) => (React.createElement(S.Item, { key: i, "data-featured": item.featured },
                        React.createElement(DocumentCard, { document: item, isLatestNewsWidget: isLatestNewsWidget })))))),
                showControls && controlsLength > 1 && (React.createElement(S.Controls, null,
                    React.createElement(S.DotHolder, { "data-hidden": controlsLength > 5 ? '1' : '0' }, new Array(controlsLength).fill(null).map((item, index) => (React.createElement(S.CarouselDot, { key: index, disabled: activeIndex === index, onClick: (event) => {
                            event.preventDefault();
                            moveTo(index);
                        } })))),
                    React.createElement(PrevButton, { disabled: activeIndex === 0, themeOption: "primaryAlt", onClick: (event) => {
                            event.preventDefault();
                            refFlicking.current?.prev();
                        } }),
                    React.createElement(NextButton, { disabled: activeIndex === controlsLength - 1, themeOption: "primaryAlt", onClick: (event) => {
                            event.preventDefault();
                            refFlicking.current?.next();
                        } })))),
            cta && (React.createElement(S.Base, null,
                React.createElement(LinkButton, { title: cta.title, target: cta.target, href: cta.url, branding: "primaryAlt" }))))));
    function moveTo(index) {
        const actualIndex = refFlicking.current?.index ?? 0;
        // Adjust index to account for cloned items.
        if (actualIndex > items.length - 1) {
            index += items.length;
        }
        const direction = index < actualIndex ? DIRECTION.PREV : DIRECTION.NEXT;
        refFlicking.current?.moveTo(index, undefined, direction);
    }
};
export default withWidget(LatestNewsCarousel, 'LatestNewsCarousel', {
    hydrate: HydrateOption.InView,
});
function getVisiblePanels(instance) {
    return instance.panels.filter((panel) => panel.visibleRatio >= 0.9);
}
